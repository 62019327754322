import { useState, useMemo, useCallback, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import _ from 'lodash'

import { ChessCore, EPieceColor, EPieceType } from 'chesscorejs'
import * as game from '../redux/thunks/game'

export default ({ gameView }) => {

	const dispatch = useDispatch()
	const chess = useMemo(() => new ChessCore(), [])
	const [selectedPosition, setSelectedPosition] = useState(null)
	const inGame = useSelector(state => state.game.inGame)

	useMemo(() => {
		if (gameView) {
			chess.board = gameView.board
			chess.status = gameView.status
		}
	}, [chess, gameView])

	const myColor = useMemo(() => gameView ? gameView.color : EPieceColor.WHITE, [gameView])
	const currentTurn = useMemo(() => chess.status.currentTurn, [chess.status.currentTurn, gameView])
	const history = useMemo(() => gameView ? gameView.history : [], [gameView])

	// Selection & Moves ---

	const validMovesBoard = useMemo(() => {
		if (!selectedPosition) return ChessCore.createBlankHeatMap()
		return ChessCore.movesToHeatMap(chess.getMoves(selectedPosition, true))
	}, [selectedPosition, chess, gameView])

	const handleClick = useCallback((row, col) => {

		const newPosition = { row, col }
		const newPiece = chess.getPieceAt(newPosition)

		if (!inGame) {
			setSelectedPosition(null)
			return
		}
		else if (currentTurn !== myColor) {
			setSelectedPosition(null)
			return
		}
		else if (newPiece && !selectedPosition && (newPiece.color !== myColor)) {
			return
		}
		else if (selectedPosition && selectedPosition.row === row && selectedPosition.col === col) {
			setSelectedPosition(null)
		}
		else if (selectedPosition && !validMovesBoard[row][col]) {
			if (newPiece && newPiece.color === myColor) setSelectedPosition(newPosition)
			else setSelectedPosition(null)
		}
		else if (selectedPosition && validMovesBoard[row][col]) {
			dispatch(game.makeMove(selectedPosition, newPosition))
			setSelectedPosition(null)
		}
		else {
			if (newPiece) setSelectedPosition(newPosition)
			else setSelectedPosition(null)
		}

	}, [validMovesBoard, chess, selectedPosition, gameView, inGame])

	// UI ---

	const boardSquares = useMemo(() => {

		let squares = []
		let selectedPiece = selectedPosition ? chess.getPieceAt(selectedPosition) : null

		const range = [0, 1, 2, 3, 4, 5, 6, 7]
		range.forEach(i => {
			range.forEach(j => {

				const _i = i, _j = j
				const piece = chess.pieceAt(i, j)
				
				squares.push(
					<div 
						className={`
							type-${((i + j) % 2) + 1} 
							${selectedPosition && selectedPosition.row === i && selectedPosition.col === j ? 'yellow' : ''} 
							${
								history.length && 
								history[history.length - 1].piece.color !== myColor &&
								((history[history.length - 1].from.row === i && history[history.length - 1].from.col === j) ||
								(history[history.length - 1].to.row === i && history[history.length - 1].to.col === j))
								? 'blue' : ''
							}
							${!!validMovesBoard[i][j] ? 'valid' : ''}
							${
								!!validMovesBoard[i][j] &&
								selectedPiece && 
								piece && 
								(selectedPiece.color !== piece.color)		
									? 'opponent red' : ''
							}
							${
								piece &&
								gameView &&
								(piece.type === EPieceType.KING) &&
								((piece.color === EPieceColor.WHITE && gameView.status.white.isCheck) ||
								(piece.color === EPieceColor.BLACK && gameView.status.black.isCheck))
								? 'check' : ''
							}
						`}
						onClick={() => handleClick(_i, _j)}
						key={(i * 8) + j}
					>
						{piece && <div className='content'>
							{ChessCore.pieceToSymbol(piece)}
						</div>}
						
						<div className='box'></div>
						<div className='dot'></div>
					</div>
				)

			})
		})
		
		if (myColor === EPieceColor.WHITE) squares.reverse()
		return squares

	}, [chess, validMovesBoard, handleClick, selectedPosition, history, gameView])

	const capturedPieces = useMemo(() => {

		let obj = {
			self: [],
			opponent: []
		}

		if (gameView) {

			obj.self = history
				.filter(log => !!log.captured)
				.map(log => log.captured)
				.filter(piece => piece.color === myColor)
				.map(piece => ChessCore.pieceToSymbol(piece))

			obj.opponent = history
				.filter(log => !!log.captured)
				.map(log => log.captured)
				.filter(piece => piece.color !== myColor)
				.map(piece => ChessCore.pieceToSymbol(piece))

		}

		return obj

	}, [gameView, history])

	// Timer countdown ---

	const [countdown, setCountdown] = useState(9000000)

	useEffect(() => {
		if (!gameView) return
		const time = currentTurn === EPieceColor.WHITE ? gameView.white.timer : gameView.black.timer
		setCountdown(time)
	}, [gameView, currentTurn])

	const useHRTimer = useMemo(() => {
		if (!gameView) return false
		else if (gameView.white.timer <= 10000 || gameView.black.timer <= 10000 || countdown <= 10000) return true
		else return false
	}, [gameView, currentTurn, countdown])

	useEffect(() => {
		if (gameView && inGame) {
			const res = useHRTimer ? 100 : 1000
			
			let tmr = setInterval(() => setCountdown(value => {
				let newVal = value - res

				if (newVal < 0) {
					newVal = 0

					clearInterval(tmr)
					tmr = undefined
					//dispatch(game.refreshGame()) -- // No longer needed - using bull-based delayed conclusion
				}

				return newVal
			}), res)

			return () => {
				if (tmr) {
					clearInterval(tmr)
					tmr = undefined
				}
			}
		}
	}, [gameView, currentTurn, useHRTimer, inGame])

	const selfTimer = useMemo(() => {
		if (!gameView) return 9000000
		else return myColor === EPieceColor.WHITE ? gameView.white.timer : gameView.black.timer
	}, [gameView, myColor])

	const opponentTimer = useMemo(() => {
		if (!gameView) return 9000000
		else return myColor === EPieceColor.BLACK ? gameView.white.timer : gameView.black.timer
	}, [gameView, myColor])

	const formatTimer = useCallback((time) => {
		const pad = (num) => {
			var s = `0${num}`;
			return s.substr(s.length - 2);
		}

		let min = pad(parseInt(time / 60000))
		let sec = pad(parseInt(time / 1000) % 60)
		let ms = parseInt((time % 1000) / 100)

		if (useHRTimer) return `${min}:${sec}.${ms}`
		else return `${min}:${sec}`
	}, [useHRTimer])

	return (<div id='main-panel'>

		<div className={`player-bar ${currentTurn !== myColor ? 'active' : ''}`}>
			<div className='timer'>{formatTimer(currentTurn !== myColor ? countdown : opponentTimer)}</div>
		</div>

		<div id='board-container'>
			<div className='captured-pieces'>{capturedPieces.self.map((c, i) => <div key={i}>{c}</div>)}</div>	
			<div id='chess-board'>{boardSquares}</div>
			<div className='captured-pieces'>{capturedPieces.opponent.map((c, i) => <div key={i}>{c}</div>)}</div>	
		</div>
		
		<div className={`player-bar ${currentTurn === myColor ? 'active' : ''}`}>
			<div className='timer'>{formatTimer(currentTurn === myColor ? countdown : selfTimer)}</div>
		</div>
	</div>)
}