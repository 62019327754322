import { EPieceColor } from "chesscorejs"

export default (
	state = {
		isConnected: false,
		isConnectionWait: false,
		isWait: false,
		inGame: false,
		showBoard: false,

		gameView: null,
		events: [],
	}
, {type, payload}) => {

	console.log(type)

	// ---

	if (type === "CONNECTION_WAIT") {
		state.isConnected = false
		state.isConnectionWait = true
	}
	if (type === "CONNECTION_ON") {
		state.inGame = payload.inGame
		state.isConnected = true
		state.isConnectionWait = false
	}
	if (type === "CONNECTION_OFF") {
		state.isConnected = false
		state.isConnectionWait = true
	}

	// ---
	
	if (type === "GAME_WAIT") {
		state.isWait = true
		state.showBoard = false
	}
	if (type === "GAME_FOUND" || type === "GAME_RESUME") {
		state.isWait = false
		state.showBoard = true
		state.inGame = true

		state.events = []
		state.gameView = payload
	}
	if (type === "GAME_NOT_FOUND") {
		state.isWait = false
		state.showBoard = false
		state.inGame = false
	}

	// ---

	if (type === "GAME_UPDATE") {
		state.gameView = payload
	}	
	if (type === "GAME_OVER") {
		state.isWait = false
		state.showBoard = true
		state.inGame = false

		state.gameView = payload
		let winner = false
		let label = 'Game Over - '
		let reason = 'Unknown Reason!'
		
		if (payload.status.isCheckmate) {
			reason = 'Checkmate!'
			if (payload.status.white.isCheck) winner = EPieceColor.BLACK
			else if (payload.status.black.isCheck) winner = EPieceColor.WHITE
			else console.error("unknown checkmate")	
		}
		else if (payload.status.isStalemate) {
			label = 'Game draws by '
			reason = "Stalemate!"
		}
		else if (payload.white.timer <= 0) {
			winner = EPieceColor.BLACK
			reason = "Time!"
		}
		else if (payload.black.timer <= 0) {
			winner = EPieceColor.WHITE
			reason = "Time!"
		}
		else console.error("unknown game conclusion")

		if (winner === payload.color) label = 'You win by '
		else if (winner !== false) label = 'Opponent wins by '
		
		state.events = [...state.events, { 
			type: "event", 
			label: label + reason, 
			timestamp: payload.timestamp, 
			highlight: true
		}]
	}
	if (type === "GAME_QUIT") {
		state.isWait = false
		state.showBoard = false
		state.inGame = false

		state.events = []
	}
	if (type === "GAME_ABANDON") {
		state.isWait = false
		state.inGame = false

		if (state.showBoard) {
			state.events = [...state.events, { type: "event", label: "Opponent has quit.", timestamp: payload.timestamp }]
		}
	}

	// ---

	if (type === 'EVENT') {
		state.events = [...state.events, payload]
	}
	if (type === 'CHAT_CLOSE') {
		if (!state.inGame) {
			state.showBoard = false
			state.events = []
		}
		else state.events = state.events.filter(log => log.type !== 'chat')
	}
	if (type === 'CHAT_CLOSE_OPPONENT') {
		if (state.showBoard && !state.inGame) {
			state.events = [...state.events, { type: "event", label: "Opponent has disconnected.", timestamp: payload }]
		}
	}

	// ---

	return { ...state }

}