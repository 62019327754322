import React from 'react'
import ReactDOM from 'react-dom'
import { createStore, applyMiddleware, combineReducers } from 'redux'
import thunk from 'redux-thunk'
import { Provider } from 'react-redux'

import TestView from './test/TestView'
import playerReducer from './redux/reducers/player'
import gameReducer from './redux/reducers/game'

import './styles.scss'

const store = createStore(
	combineReducers({
		player: playerReducer,
		game: gameReducer,
	})
, applyMiddleware(thunk))

ReactDOM.render(
	<React.StrictMode>
		<Provider store={store}>
			<TestView />
		</Provider>
	</React.StrictMode>,
	document.getElementById('root')
)
