export default ({
	label,

	isSub = false,
	isLarge = false,
	isLight = false,

	onClick = () => false,
	isDisabled = false,
	icon,
	isFluid=false,
	hasSpinner=false
}) => {

	return <div 
		className={`
			button ${icon ? '' : 'no-icon'} 
			${isSub ? 'sub' : ''} 
			${isLarge ? 'large' : ''} 
			${isLight ? 'light' : ''} 
			${isDisabled ? 'disabled' : ''} 
			${isFluid ? 'fluid' : ''} 
			${hasSpinner ? 'blue' : ''}`
		}
		onClick={e => isDisabled ? false : onClick(e)}
	>	
		{hasSpinner && <div className="lds-dual-ring"></div>}
		{icon && <i className={`las ${icon}`}></i>}
		{label && <div>{label}</div>}
	</div>

}