import React, { useMemo, useCallback } from "react"
import { useDispatch, useSelector } from 'react-redux'

import Button from "../components/Button"

import * as game from "../redux/thunks/game"

export default ({}) => {

	const dispatch = useDispatch()
	const inGame = useSelector(state => state.game.inGame)
	const isWait = useSelector(state => state.game.isWait)

	const handleClick = useCallback(() => {
		if (isWait) dispatch(game.cancelFind())
		else dispatch(game.findOrResume())
	}, [isWait])

	const buttonlabel = useMemo(() => {
		if (isWait) {
			if (inGame) return 'Loading'
			else return "Cancel Matchmaking"
		}
		else {
			if (inGame) return 'Resume Game'
			else return "Find Match"
		}
	}, [inGame, isWait])

	return <React.Fragment>
		<div id='big-icon'></div>
		<div id='app-title'>Chess Roulette</div>
		<Button 
			label={buttonlabel}
			isLarge={true} 
			hasSpinner={isWait}
			onClick={handleClick}
		/>
	</React.Fragment>

}