export default (
	state = {
		isWait: false,
		inGame: undefined
	}
, {type, payload}) => {

	if (type === 'UPDATE_PLAYER_WAIT') {
		state.isWait = false
		state.inGame = undefined
	}

	else if (type === 'UPDATE_PLAYER_DONE') {
		state.isWait = true
		state.inGame = payload.inGame
	}

	return state

}