import { io } from 'socket.io-client'
import axios from "axios"

import { APIURL } from '../../utils'

let socket
let _dispatch

export const connect = () => async (dispatch, getStatus) => {
	_dispatch = dispatch
	if (!socket) return new Promise(async (resolve, reject) => {

		dispatch({ type: 'CONNECTION_WAIT' })
		
		const res = await getElo()
		if (!res) {
			dispatch({ type: 'CONNECTION_OFF' })
			reject("Unable to establish WS")
			return
		}	

		socket = io(APIURL(), { withCredentials: true, secure: window.location.hostname !== 'localhost' ? true : false })
		const tmout = setTimeout(() => {
			if (!socket.connected) {
				socket.disconnect()
				socket = undefined

				dispatch({ type: 'CONNECTION_OFF' })
				reject("Unable to establish WS")
			}
		}, 5000)

		socket.on('connect', async () => {
			clearTimeout(tmout)

			socket.on('disconnect', async () => {
				socket.disconnect()
				socket = undefined
				dispatch({ type: 'CONNECTION_OFF' })
			})

			socket.onAny((event, data) => {
				const handler = WSEventToFn[event]
				if (handler) handler(data)
			})

			resolve(true)
		})

	})
}

export const findOrResume = () => async (dispatch, getState) => {
	socket.emit('game.find')
}

export const cancelFind = () => async (dispatch, getState) => {
	socket.emit('game.cancelFind')
}

export const quitGame = () => async (dispatch, getState) => {
	socket.emit('game.quit')
}

export const makeMove = (from, to) => async (dispatch, getState) => {
	socket.emit('game.update', { move: { from, to}} )
}

export const sendMessage = (msg) => async (dispatch, getState) => {
	socket.emit('chat.message', { message: msg })
}

export const closeChat = () => async (dispatch, getState) => {
	dispatch({ type: 'CHAT_CLOSE' })
	socket.emit('chat.close')
}

export const refreshGame = () => async (dispatch, getState) => {
	//socket.emit('game.refresh')
	console.log('expect conclusion')
}

const getElo = async () => {
	const res = await axios.get(`${APIURL()}/status/elo`, { withCredentials: true })
	if (!res.data.elo) return false
	else return res
}

const claimTicket = async () => {
	await axios.get(`${APIURL()}/status/ticket`, { withCredentials: true })
}

const WSEventToFn = {
	'self.online': ({ inGame }) => _dispatch({ type: 'CONNECTION_ON', payload: { inGame } }),
	'opponent.offline': ({ timestamp }) => _dispatch({ type: 'EVENT', payload: { type: 'event', label: 'Opponent has disconnected.', timestamp } }),
	'opponent.online': ({ timestamp }) => _dispatch({ type: 'EVENT', payload: { type: 'event',  label: 'Opponent has re-connected.', timestamp } }),

	'game.ticket': () => claimTicket(),
	'game.found': (gameView) => _dispatch({ type: 'GAME_FOUND', payload: gameView }),	
	'game.resume': (gameView) => _dispatch({ type: 'GAME_RESUME', payload: gameView }),

	'game.quit': () => _dispatch({ type: 'GAME_QUIT' }),
	'game.abandon': ({ timestamp }) => _dispatch({ type: 'GAME_ABANDON', payload: {  timestamp } }) && getElo(),
	'game.over': (gameView) => _dispatch({ type: 'GAME_OVER', payload: gameView }) && getElo(),

	'game.none': () => _dispatch({ type: 'GAME_NOT_FOUND' }),
	'game.wait': () => _dispatch({ type: 'GAME_WAIT' }),
	'game.update': (gameView) => _dispatch({ type: 'GAME_UPDATE', payload: gameView }),

	'chat.message': (msgLog) => _dispatch({ type: 'EVENT', payload: { type: 'chat', ...msgLog }}),
	'chat.close': ({ timestamp }) => _dispatch({ type: 'CHAT_CLOSE_OPPONENT', payload: timestamp })
}