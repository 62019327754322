import React, { useEffect, useState, useMemo, useCallback, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { ChessCore, EPieceColor } from 'chesscorejs'
import * as game from '../redux/thunks/game'

export default ({ gameView }) => {

	const dispatch = useDispatch()

	const inGame = useSelector(state => state.game.inGame)
	const showBoard = useSelector(state => state.game.showBoard)
	const myColor = useMemo(() => gameView ? gameView.color : EPieceColor.WHITE, [gameView])

	const events = useSelector(state => state.game.events)
	const [message, setMessage] = useState('')
	const activityEl = useRef(null)
	const history = useMemo(() => gameView ? gameView.history : [], [gameView])

	// ---

	const activityArr = useMemo(() => {
		if (!gameView) return []

		let arr = []
		let e = 0, h = 0
		let selfStreak = 0, opponentStreak = 0

		const addEvent = (event) => {
			let streak

			if (event.type === 'chat') {
				if (event.fromSelf) {
					selfStreak++
					streak = selfStreak
					opponentStreak = 0
				}
				else {
					opponentStreak++
					streak = opponentStreak
					selfStreak = 0
				}
				arr.push({ ...event, streak })
			}
			else {
				selfStreak = 0
				opponentStreak = 0
				arr.push(event)
			}
		}

		const addMove = (move) => {
			selfStreak = 0
			opponentStreak = 0
			arr.push({ type: 'move', ...move })
		}

		while (e < events.length && h < history.length) {	
			if (new Date(history[h].timestamp) < new Date(events[e].timestamp)) {
				addMove(history[h])
				h++
			}
			else {
				addEvent(events[e])
				e++
			}
		}

		while (e < events.length) {
			addEvent(events[e])
			e++
		}

		while (h < history.length) {
			addMove(history[h])
			h++
		}

		return arr
	}, [gameView, events])

	useEffect(() => {
		if (activityEl) {
			const oMut = new MutationObserver(() => {
				activityEl.current.scroll({ top: activityEl.current.scrollHeight, behavior: 'smooth' })
			}).observe(activityEl.current, { childList: true })

			return () => oMut.disconnect()
		}
	}, [activityEl])

	// ---

	const renderMove = useCallback((move, key) => (
		<div
			className={`move ${move.piece.color === myColor ? 'self' : 'opponent'}`}
			key={key}
		>
			<div className='piece'>{ChessCore.pieceToSymbol(move.piece)}</div>
			<div className='from'>{ChessCore.translatePosition(move.from)}</div>
			<i className="las la-long-arrow-alt-right"></i>
			<div className='to'>{ChessCore.translatePosition(move.to)}</div>
			{move.captured && <div className='captured'>{ChessCore.pieceToSymbol(move.captured)}</div>}
		</div>
	), [myColor])

	const renderChatMessage = useCallback((msg, key) => (
		<div
			className={`msg ${msg.fromSelf ? 'self' : 'opponent'}`}
			key={key}
		>
			{(msg.streak === 1) && msg.fromSelf && <div className='label'>You</div>}
			{(msg.streak === 1) && !msg.fromSelf && <div className='label'>Stranger</div>}
			{msg.message}
		</div>
	), [])

	const renderEvent = useCallback((event, key) => (
		<div className='event' key={key} >{event.label}</div>
	), [])

	// ---

	const sendMessage = useCallback(e => {
		if (e.key === 'Enter') {
			dispatch(game.sendMessage(message))
			setMessage('')
		}
	}, [message])

	const handleResign = useCallback(() => {
		if (showBoard && !inGame) dispatch(game.closeChat())
		else dispatch(game.quitGame())
	}, [inGame, showBoard])

	return (<div id='side-panel'>

		<div className="player-bar">

		</div>

		<div id="activity" ref={activityEl}>
			{activityArr.map((activity, i) => {
				if (activity.type === 'move') return renderMove(activity, i)
				else if (activity.type === 'chat') return renderChatMessage(activity, i)
				else return renderEvent(activity, i)
			})}
		</div>

		<div id="chat-input">
			<input 
				type='text' 
				value={message} 
				onChange={e => setMessage(e.target.value)}
				onKeyDown={sendMessage}
				placeholder={'Type a message..'}
			></input>
		</div>

		<div className="player-bar">
			<div 
				className='red icon-right' 
				onClick={handleResign}
			>
				Quit Game
				<i className="las la-flag"></i>
			</div>
		</div>

	</div>)

}