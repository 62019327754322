import React, { useEffect, useState, useMemo } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import * as game from '../redux/thunks/game'

import ChessBoard from "../components/ChessBoard"
import FindGame from '../modals/FindGame'
import Modal from '../components/Modal'
import ActivityPanel from '../components/ActivityPanel'

export default ({}) => {

	const dispatch = useDispatch()
	const isConnected = useSelector(state => state.game.isConnected)
	const showBoard = useSelector(state => state.game.showBoard)
	const gameView = useSelector(state => state.game.gameView)

	useEffect(() => dispatch(game.connect()), [])

	return (<React.Fragment>
		<div id='game'>
			<ChessBoard gameView={gameView} />
			<ActivityPanel gameView={gameView} />
		</div>

		{(!showBoard || !isConnected) && <Modal>
			{isConnected && <FindGame />}
		</Modal>}
	</React.Fragment>)

}