export default ({ 
	children, 
	closeAction,
}) => (
	<div className='backdrop active' onClick={closeAction || (() => false)}>
		<div className='modal' onClick={e => e.stopPropagation()}>
			{children}
			{closeAction && <div id='modal-close' onClick={closeAction}><i className="las la-times"></i></div>}
		</div>
	</div>
)